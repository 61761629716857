import React from "react"

import styles from "./content-header.module.scss"
import Text1 from "../text/text-1"
import Text2 from "../text/text-2"

const ContentHeader = ({ title, description, imagePath, videoPath, children, playButtonUrl, playButtonCopy }) => {
  return (
    <div className={styles.container}>
      <div className={styles.gradient} />
      <div className={styles.pattern} />
      <div className={styles.content}>
        <div className={styles.inner}>
          {!children ? (
            <div>
              <Text1 text={title} color="white" />
              {description ? (
                <div>
                  <div className={styles.spacer} />
                  <Text2 text={description} color="white" />
                </div>
              ) : null}
              {playButtonCopy && playButtonUrl ? (
                <div className={styles.playButtonContainer}>
                  <div className={styles.spacer} />
                  <a className={styles.playButtonWrapper} href={playButtonUrl} target="_blank" rel="noopener noreferrer">
                    <img className={styles.playButtonIcon} src={require("../../images/play.svg")} alt="" />
                    <div className={styles.playButtonCopy}>{playButtonCopy}</div>
                  </a>
                </div>
              ) : null}
            </div>
          ) : null}
        </div>
      </div>
      <div className={styles.image} style={{ backgroundImage: `url(${imagePath})` }} />
      {videoPath ? (
        <div className={styles.videoContainer}>
          <video className={styles.video} src={videoPath} autoPlay loop playsInline muted />
        </div>
      ) : null}
    </div>
  )
}

export default ContentHeader
