import React from "react"
import { graphql, Link } from "gatsby"
import { TransitionPortal } from "gatsby-plugin-transition-link"

import Layout from "../components/layout/layout"
import PageTransition from "../components/transition/page"
import SEO from "../components/seo/seo"
import Thumbnail from "../components/thumbnail/thumbnail"
import VideoPlayer from "../components/player/video"
import Paragraph from "../components/textfield/components/paragraph/paragraph"
import ContentHeader from "../components/layout/content-header"

class StaticTemplate extends React.Component {

    render(){

        return (
            <Layout theme="light" path={this.props.path} locale="en-AU" exit={{ length: 1.75, trigger: () => this.animate() }} entry={{ delay: 0.875 }}>
                        <SEO
                            title="2020 Ticket Holders"
                        />
                        <div className={"container mx-auto"}>
                            <div className={"w-full lg:w-10/12 lg:px-4 mx-auto "}>
                                <ContentHeader title="2020 Ticket Holders" imagePath="//images.ctfassets.net/8ipgrsmep6cq/3g4IcVymdBZN1C89ogCM2N/542d04de7d0fad9fbbb717e441859e33/The_Drop__Miranda_Stokkel__18_of_28___2_.jpg" />
                            </div>
                            <div className={"container mx-auto"}>
                                <div className={"px-2 w-2/3 mx-auto"}>
                                        <Paragraph children="Hi" />
                                        <Paragraph children="This page will be live at 9am AEST on Friday 15th of July to rollover 2020 Drop Festival tickets to a NSW event in 2022 or for 2020 Manly ticket holders to register to receive your refund." />
                                        <Paragraph children="Please return after 9am on Friday 15th of July." />
                                        <Paragraph children="Thanks" />
                                        <Paragraph children="The Drop Team" />
                                </div>
                            </div>
                        </div>
              <TransitionPortal>
                <PageTransition ref={this.pageTransition} />
              </TransitionPortal>
          </Layout>
        )
    }

}

export default StaticTemplate
