import React from "react"
import styles from "./text-1.module.scss"

const Text1 = ({ text, color }) => (
  <div className={styles.text} style={{ color }}>
    {text}
  </div>
)

export default Text1
