import React from "react"
import styles from "./text-2.module.scss"

const Text2 = ({ text, color, bold }) => (
  <div
    className={styles.text}
    style={{ color, fontWeight: bold ? "900" : "regular" }}
  >
    {text}
  </div>
)

export default Text2
